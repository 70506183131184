import { render, staticRenderFns } from "./AnalysisSettings.vue?vue&type=template&id=24c7d030&scoped=true"
import script from "./AnalysisSettings.vue?vue&type=script&lang=js"
export * from "./AnalysisSettings.vue?vue&type=script&lang=js"
import style0 from "./AnalysisSettings.vue?vue&type=style&index=0&id=24c7d030&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c7d030",
  null
  
)

export default component.exports