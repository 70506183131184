<template>
  <el-dialog
    title="編輯 Facebook Pixel (像素）"
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="formData">
      <el-form-item prop="fbPixelCode">
        <template slot="label">
          <FormLabelWithHint
            text="Facebook Pixel (像素）"
            hint="(必須申請Facebook事件管理工具才能使用此服務)"
          />
        </template>
        <el-input v-model="formData.fbPixelCode" placeholder="請輸入 Facebook Pixel (像素）" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        type="primary"
        @click="updateSetting"
      >
        儲存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormLabelWithHint from '@/components/Form/FormLabelWithHint.vue'
import { UpdateEcommerceConfig } from '@/api/ecommerce/paramter'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/form'
import { noEmptyRules } from '@/validation/index'
import { get } from 'lodash'

export default {
  name: 'FackbookPixelSettingDialog',
  components: { FormLabelWithHint },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    show: false,
    formData: {
      fbPixelCode: '',
    },
    formRules: {
      fbPixelCode: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
    ]),
  },
  mounted () {
    this.show = true
    this.formData.fbPixelCode = get(this.config, 'fbPixelCode', '')
  },
  methods: {
    // => 更新其他設定
    async updateSetting () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const [, error] = await UpdateEcommerceConfig({
        shopId: this.shop,
        fbPixelCode: this.formData.fbPixelCode || null,
      })

      if (error) this.$message.error(error)
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
  },
}
</script>
